import "./assets/css/bootstrap-icons.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fonts.css";
import "./assets/css/mst.css";
import Header from "./components/header";
import Content from "./components/content";
import ProjectInfo from "./components/projectinfo";
import MiddleSection from "./components/middlesection";
import Footer from "./components/footer";
function App() {
  return (
    <div className="App">
      <Header />
      <Content />
      <MiddleSection />
      {/* <ProjectInfo />
      <Footer /> */}
    </div>
  );
}

export default App;
