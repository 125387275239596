export default function Header() {
  return (
    <nav className="navbar bg-light bottom-line">
      <div className="container">
        <a className="navbar-brand logo" href="./">
          My Senior, Today
        </a>
      </div>
    </nav>
  );
}
