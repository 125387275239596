export default function Content() {
  return (
    <div className="container-fluid bg-img">
      <div className="container py-5">
        <h1 className="mx-auto">
          People must help one another,
          <br />
          it is nature's law.
        </h1>
        <h4>~ Jean de La Fontaine</h4>
        <p className="underconstruction">Under Construction</p>
      </div>
    </div>
  );
}
